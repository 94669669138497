<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :title="title"
    :size="'lg'"
    :centered="true"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
  <div>
    <label>Reserva</label>
    <table class="table table-hover">
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>A nombre de</th>
          <th>Serie</th>
          <th>Número</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(deposit, index) in deposits_reserva" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ deposit.voucher.date }}</td>
          <td>{{ deposit.voucher.voucher_fullname }}</td>
          <td>{{ deposit.voucher.serie }}</td>
          <td>{{ deposit.voucher.number }}</td>
          <td>{{ deposit.amount }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5" class="text-right font-weight-bold">Total:</td>
          <td class="font-weight-bold">{{ totalAmountReserva() }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
  <div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th width="60">N°</th>
          <th>Fecha</th>
          <th>A nombre de</th>
          <th>Serie</th>
          <th>Número</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(deposit, index) in deposits" :key="index">
          <td>{{ index+1}}</td>
          <td>{{ deposit.voucher.date }}</td>
          <td>{{ deposit.voucher.voucher_fullname }}</td>
          <td>{{ deposit.voucher.serie }}</td>
          <td>{{ deposit.voucher.number }}</td>
          <td>{{ deposit.amount }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5" class="text-right font-weight-bold">Total:</td>
          <td class="font-weight-bold">{{ totalAmount() }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
    
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
    </template>
  </CModal>
</template>
<script>
import CModal from "../../shared/modals/cModalFormCom.vue";
import { mapActions } from "vuex";

export default {
  name: "c-deposit-modal",
  components: {
    CModal,
  },
  data() {
    return {
      prefix: "beads",
      data_chargue: [],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    deposits: Array,
    deposits_reserva: Array,
  },
  watch: {
  },
  created() {},
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModalDeposit");
    },
    totalAmountReserva() {
      return this.deposits_reserva?.reduce((total, deposit) => {
        return total + parseFloat(deposit.amount);
      }, 0).toFixed(2); // Asegúrate de mostrar solo dos decimales
    },
    totalAmount() {
      return this.deposits?.reduce((total, deposit) => {
        return total + parseFloat(deposit.amount);
      }, 0).toFixed(2); // Asegúrate de mostrar solo dos decimales
    },
  },
};
</script>
<style scoped>
</style>
