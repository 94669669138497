<template>
  <div v-if="booFooter">
    <nav id="nav-menu" class="fixed-bottom d-flex justify-content-center mb-5">
      <div :class="view == 'home'? 'nav-select navbar-brand pl-0 text-white text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark':'navbar-brand pl-0 text-black text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark'" @click="mtdSelectView('home')"><span>INICIO</span></div>
      <div :class="view == 'mantenimiento'? 'nav-select navbar-brand pl-0 text-white text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark':'navbar-brand pl-0 text-black text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark'" @click="mtdSelectView('mantenimiento')"><span>MANTENIMIENTOS</span></div>
      <div :class="view == 'reserva'? 'nav-select navbar-brand pl-0 text-white text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark':'navbar-brand pl-0 text-black text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark'" @click="mtdSelectView('reserva')"><span>RESERVA</span></div>
      <div :class="view == 'VistaNew'? 'nav-select navbar-brand pl-0 text-white text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark':'navbar-brand pl-0 text-black text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark'" @click="mtdSelectView('vista_new')"><span>LISTA DE RESERVAS</span></div>
      <div :class="view == 'proccess'? 'nav-select navbar-brand pl-0 text-white text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark':'navbar-brand pl-0 text-black text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark'" @click="mtdSelectView('proccess')"><span>PROCESOS</span></div>
      <div :class="view == 'reporte'? 'nav-select navbar-brand pl-0 text-white text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark':'navbar-brand pl-0 text-black text-center pr-0 nav-footer w-100 border-end border-white border-3 border-dark'"
      @click="mtdSelectView('reporte')"><span>REPORTE</span></div>
    </nav>
  </div>
</template>

<script>
export default {
  props:{
    booFooter:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      view: 'home',
    };
  },
  computed: {

  },
  methods: {
    mtdSelectView: function(view){
      this.view = view;
      this.$emit('setView',this.view);
    }
  },
};
</script>

<style scoped>
#nav-menu{
  background-color: white;
}
.nav-select{
  background-color: #926cdc;
}
#contenedor-menu {
  justify-content: center;
}

#lista_menu_home {
  font-family:Arial;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding-left: 0 !important;
}

li {
  background-color: #926cdc;
  float: left;
  text-align: center;
  text-decoration:none;
}

li a{
  color:#ffffff;
  font-weight:bold;
  margin: 0;
}

.nav-footer:hover {
  cursor: pointer;
  color: white;
  /* background-color:rgb(162, 162, 162); */
  background: #926cdc;
}

#nav{
    position: absolute;
    float:left;
    bottom:0px;
}
#img_logo{
    position: absolute;
    float:left;
    left:-5px;
    top:-5px;
}

@media(max-width: 800px){
  #title-user, #title-cash, #title, #title-date{
    text-align: right !important;
  }
  #img_logo img{
    width: 175px !important;
  }

  li {
    float: none;
  }

  #nav-menu {
    position: inherit;
  }
  /**  */
  #nav-menu{
    flex-direction: column;
  }
}
@media(min-width: 800px){
  #title-cash, #title, #title-date{
    text-align: center !important;
  }
  #title-user{
    text-align: right !important;
  }
}

@media (min-height: 150px) and (max-height: 350px) {
  #nav-menu{
    margin-bottom: 0px !important;
    /* bottom: 0px; */
  }
}
</style>

