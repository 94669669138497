<template>
    <div>
      <div>
        <HomeVue v-if="view == 'home'" @mtdBackCampus="mtdBackCampus" @setView="setView"/>
        <CloseBeadVue v-if="view == 'close_bead'" @setView="setView"/>
        <ReservaVue v-if="view == 'reserva'" @mtdViewFooter="mtdViewFooter" @setView="setView"/>
        <MaintenanceVue v-if="view == 'mantenimiento'" @mtdViewFooter="mtdViewFooter" />
        <VistaNewVue v-if="view == 'vista_new'"  @mtdViewFooter="mtdViewFooter" @setView="setView"/>
        <ProccessVue v-if="view == 'proccess'"  @mtdViewFooter="mtdViewFooter" @setView="setView"/>
        <VistaReporte v-if="view == 'reporte'"  @mtdViewFooter="mtdViewFooter" @setView="setView"/>
      </div>
        <FooterVue v-if="!(view == 'reserva') && !(view == 'vista_new')&& !(view == 'reporte')" :view="view" @setView="setView" :booFooter="booFooter" />
    </div>
</template>

  <script>
  import FooterVue from "../../components/adminCompany/Footer.vue";
  import HomeVue from "../../components/adminCompany/Home.vue";
  import CloseBeadVue from '../../components/receptionist/page/CloseBead.vue';
  import MaintenanceVue from "../maintenance/MaintenanceView.vue";
  import VistaNewVue from '../../components/receptionist/page/VistaNew.vue';
  import ReservaVue from '../../components/receptionist/page/Booking.vue';
  import VistaReporte from '../../components/adminCompany/reporteMensual.vue';
  import ProccessVue from '../proccess/ProccessView.vue'

  export default {
    components: { FooterVue, HomeVue, CloseBeadVue, MaintenanceVue, ReservaVue, VistaNewVue,VistaReporte, ProccessVue},
    data() {
      return {
        view: "home",
        booFooter:true
      };
    },
    methods: {
      mtdBackCampus: function(){
        this.$emit("mtdBackCampus");
      },
      setView: function (payload) {
        this.view = payload;
      },
      mtdViewFooter: function(boo,view){
        this.booFooter = boo;
        if(boo == true) this.view = view;
      }
    },
  };
  </script>

  <style lang="scss" scoped></style>
