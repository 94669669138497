<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper :items="data" :fields="fields" hover :striped="true" :border="true" small fixed
          caption="Lista de Proveedores" icon="fas fa-coins" btn_name="Proveedor" @mtdBack="mtdBack"
          @show_modal="mtd_open_modal" @action_edit="mtd_show" @action_delete="mtd_delete" :size="'md'"
          :button_new="true" :actions="true" :withActions="'2%'" :buttonEdit="true" :buttonDelete="true" />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="modal.title" :button="cp_button"
      :show.sync="modal.modal_form" @mtd_action="mtd_action" :color="'info'" :centered="true">
      <CRow>
        <CCol sm="12">
          <CInput label="Nombre" placeholder="Digite nombre" v-model="provider.name" />
        </CCol>
        <CCol sm="12">
          <CInput label="RUC" placeholder="Digite RUC" v-model="provider.ruc" maxlength="11" @input="validateRUC" />
          <span v-if="errors.ruc" class="text-danger">{{ errors.ruc }}</span>
        </CCol>
        <CCol sm="12">
          <CInput label="Telefono" placeholder="Digite telefono" v-model="provider.phone" maxlength="9"
            @input="validatePhone" />
          <span v-if="errors.phone" class="text-danger">{{ errors.phone }}</span>
        </CCol>
        <CCol sm="12">
          <CInput label="Representante" placeholder="Digite representante" v-model="provider.vendor"
            @input="ValidateVendor" />
          <span v-if="errors.vendor" class="text-danger">{{ errors.vendor }}</span>
        </CCol>
        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue :key="componentKey" @changeSwitch="changeSwitch" class="mx-1 pl-2 pt-2"
            :checked="provider.state == 1 ? true : false" color="success" variant="opposite" shape="pill"
            v-bind="labelIcon" />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="'Borrar Proveedor'"
      :show.sync="modal_delete.boo" @mtd_commit="mtd_commit" :centered="true" :item="modal_delete.item" />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "ruc", label: "RUC", _style: "width:20%;" },
  { key: "phone", label: "Telefono", _style: "width:20%;" },
  { key: "vendor", label: "Representante", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, CSwitchVue },
  data() {
    return {
      prefix: "providers",
      fields,
      data: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      provider: {
        id: "",
        company_id: this.$store.getters.get_company.id,
        name: "",
        ruc: "",
        phone: "",
        vendor: "",
        state: 0,
      },
      errors: {
        phone: "",
        ruc: "",
        vendor: ""
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.provider.state == 1) return true;
      return false;
    },
    cp_button: function () {
      return this.errors.ruc !== "" ||
        this.errors.phone !== "" ||
        this.errors.vendor !== "" ||
        this.provider.name === "" ||
        this.provider.ruc === "" ||
        this.provider.phone === "" ||
        this.provider.vendor === "";
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix + "/index/" + this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.data;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          })
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Proveedor")
        : (this.modal.title = "Editar Proveedor");
      action == "store"
        ? (this.provider = {
          id: "",
          company_id: this.$store.getters.get_company.id,
          name: "",
          ruc: "",
          phone: "",
          vendor: "",
          state: 0,
        })
        : (this.provider = { ...this.provider });
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      if (!this.validateForm()) return;
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.provider,
      })
        .then((response) => {
          if (response.data.state == 1 || response.data.state == 2) {
            if (response.data.state == 1) {
              Swal.fire({
                title: "Proveedor ya resgistrado",
                icon: "warning"
              });
            } else {
              Swal.fire({
                title: "RUC ya resgistrado",
                icon: "warning"
              });
            }

          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedProvider = response.data.data;
              let index = this.data.findIndex(provider => provider.id === updatedProvider.id);
              if (index !== -1) {
                this.$set(this.data, index, updatedProvider);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            this.provider = { id: "", company_id: this.$store.getters.get_company.id, name: "", ruc: "", phone: "", vendor: "", state: 0 };

            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.provider = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => { });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => { });
    },
    changeSwitch: function (payload) {
      this.provider.state = payload == true ? 1 : 0;
    },
    ValidateVendor() {
      const regex = /^[A-Za-z\s]+$/;
      if (this.provider.vendor && !regex.test(this.provider.vendor)) {
        this.errors.vendor = "El nombre del representante solo puede contener letras";
      } else {
        this.errors.vendor = "";
      }
    },
    validateRUC() {
      // Filtra solo los caracteres numéricos
      this.provider.ruc = this.provider.ruc.replace(/\D/g, "");

      if (this.provider.ruc.length != 11 || isNaN(this.provider.phone)) {
        this.errors.ruc = "El RUC debe ser un número de máximo 11 dígitos";
      } else {
        this.errors.ruc = "";
      }
    },
    validatePhone() {
      // Filtra solo los caracteres numéricos
      this.provider.phone = this.provider.phone.replace(/\D/g, "");

      if (this.provider.phone.length != 9 || isNaN(this.provider.phone)) {
        this.errors.phone = "El teléfono debe ser un número de máximo 9 dígitos";
      } else {
        this.errors.phone = "";
      }
    },
    validateForm() {
      this.validateRUC();
      this.validatePhone();
      this.ValidateVendor();
      return (
        this.errors.ruc === "" &&
        this.errors.phone === ""&&
        this.errors.vendor === ""
      );
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
  .btn-search {
    color: #fff;
    background-color: #2819ae;
    border-color: #2517a3;
  }
</style>
