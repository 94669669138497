<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper :items="data" :fields="fields" hover :striped="true" :border="true" small fixed
          caption="Lista de Empresas" icon="fas fa-coins" btn_name="Empresa" @mtdBack="mtdBack"
          @show_modal="mtd_open_modal" @action_edit="mtd_show" @action_delete="mtd_delete" :size="'md'"
          :button_new="true" :actions="true" :withActions="'2%'" :buttonEdit="true" :buttonDelete="true" />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="modal.title" :button="cp_button"
      :show.sync="modal.modal_form" @mtd_action="mtd_action" :color="'info'" :centered="true">
      <CRow>
        <CCol sm="12">
          <CInput label="Razón social" placeholder="Digite Razón social" v-model="companies.reason_social" />
          <CInput label="RUC" placeholder="Digite RUC" v-model="companies.ruc" maxlength="11" @input="validateRUC" />
          <span v-if="errors.ruc" class="text-danger">{{ errors.ruc }}</span>
          <CInput label="Telefono" placeholder="Digite Telefono" v-model="companies.phone" maxlength="9"
            @input="validatePhone" />
          <span v-if="errors.phone" class="text-danger">{{
            errors.phone
          }}</span>
          <CInput label="Correo" placeholder="Digite Correo" v-model="companies.email"
            @input="validateEmail(companies.email)" type="email" />
          <span v-if="errors.email_company" class="text-danger">{{
            errors.email_company
          }}</span>
          <div class="mb-2">¿Desea apertura y cierre de caja?</div>
          <CSwitchVue @changeSwitch="handleSwitchChange" v-model="companies.box" color="success" variant="opposite"
            shape="pill" class="" :checked="companies.box == 1" v-bind="labelIcon" />
          <div class="mb-2">¿Realiza devolucion de dinero?</div>
          <CSwitchVue @changeSwitch="handleSwitchChangeDevolution" v-model="companies.devolution" color="success"
            variant="opposite" shape="pill" class="" :checked="companies.devolution == 1" v-bind="labelIcon" />
          <div class="mb-2">Logo</div>
          <input @change="getFile" type="file" class="form-control" id="file" lang="es" name="file" ref="fileInput"
            accept=".png,.jpg,.jpeg" />
          <div v-if="companies.logo && modal.action !== 'store'" class="container-img">
            <img v-if="typeof companies.logo === 'string' && companies.logo" :src="'https://iareservagol.howertpi.com/assets/' + companies.logo
              " class="img-thumbnail" style="max-width: 100px" alt="Company Logo" />
            <p v-else></p>
          </div>
        </CCol>
        <CCol sm="12">
          <label v-if="modal.action === 'store'">Datos del Representante</label>
          <CInput v-if="modal.action === 'store'" label="Nombre" placeholder="Digite nombre del representante"
            v-model="employees.name" @input="ValidateName" />
          <span v-if="errors.name" class="text-danger">{{ errors.name }}</span>

          <CInput v-if="modal.action === 'store'" label="Apellido" placeholder="Digite apellido del representante"
            v-model="employees.last_name" @input="ValidateLastName" />
          <span v-if="errors.last_name" class="text-danger">{{ errors.last_name }}</span>

          <CInput v-if="modal.action === 'store'" label="Dni" placeholder="Digite Dni" v-model="employees.dni"
            maxlength="8" @input="validateDNI" />
          <span v-if="modal.action === 'store' && errors.dni" class="text-danger">{{ errors.dni }}</span>

          <CInput v-if="modal.action === 'store'" label="Telefono" placeholder="Digite Telefono"
            v-model="employees.phone" @input="validatePhoneEmployee" maxlength="9" />
          <span v-if="modal.action === 'store' && errors.phone_employee" class="text-danger">{{ errors.phone_employee
            }}</span>

          <CInput v-if="modal.action === 'store'" label="Correo" placeholder="Digite Correo" type="email"
            @input="validateEmail(employees.email)" v-model="employees.email" />
          <span v-if="modal.action === 'store' && errors.email_employee" class="text-danger">{{ errors.email_employee
            }}</span>

          <CInput v-if="modal.action === 'store'" label="Dirección" placeholder="Digite Dirección"
            v-model="employees.address" />
        </CCol>

        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue :key="componentKey" @changeSwitch="changeSwitch" class="mx-1 pl-2 pt-2"
            :checked="companies.state == 1 ? true : false" color="success" variant="opposite" shape="pill"
            v-bind="labelIcon" />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="'Borrar Empresa'"
      :show.sync="modal_delete.boo" @mtd_commit="mtd_commit" :centered="true" :item="modal_delete.item" />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "reason_social", label: "Razón Social", _style: "width:10%;" },
  { key: "ruc", label: "RUC", _style: "width:10%;" },
  { key: "phone", label: "Teléfono", _style: "width:10%;" },
  { key: "email", label: "Correo", _style: "width:10%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    CSwitchVue,
    cSelectForm,
  },
  data() {
    return {
      prefix: "companies",
      fields,
      data: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      companies: {
        id: "",
        reason_social: "",
        ruc: "",
        phone: "",
        email: "",
        logo: "",
        state: 0,
        box: 0,
        devolution: 0,
      },
      employees: {
        id: "",
        name: "",
        last_name: "",
        dni: "",
        address: "",
        phone: "",
        email: "",
        state: 0,
      },
      errors: {
        ruc: "",
        phone: "",
        dni: "",
        phone_employee: "",
        email_company: "",
        email_employee: "",
        last_name: "",
        name: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
    };
  },
  computed: {
    cpStatus: function () {
      if (this.companies.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (this.modal.action == "store") {
        if (
          this.companies.reason_social !== "" &&
          this.companies.ruc !== "" &&
          this.companies.phone !== "" &&
          this.companies.email !== "" &&
          this.companies.logo !== "" &&
          this.employees.name !== "" &&
          this.employees.last_name !== "" &&
          this.employees.dni !== "" &&
          this.employees.email !== "" &&
          this.employees.address !== "" &&
          this.errors.email_company == "" &&
          this.errors.email_employee == "" &&
          this.errors.name == "" &&
          this.errors.last_name == ""
        )
          return false;
        return true;
      }
      if (this.modal.action == "update") {
        if (
          this.companies.reason_social !== "" &&
          this.companies.ruc !== "" &&
          this.companies.phone !== "" &&
          this.companies.email !== "" &&
          this.errors.email_company == ""
          // this.errors.name ==""&&
          // this.errors.last_name ==""
        )
          return false;
        return true;
      }
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    handleSwitchChange(payload) {
      this.companies.box = payload ? 1 : 0;
    },
    handleSwitchChangeDevolution(payload) {
      this.companies.devolution = payload ? 1 : 0;
    },
    getFile(e) {
      let fileInput = this.$refs.fileInput;
      let file = e.target.files[0];
      if (
        !/\.(png)$/i.test(file.name) &&
        !/\.(jpg)$/i.test(file.name) &&
        !/\.(jpeg)$/i.test(file.name)
      ) {
        fileInput.value = "";
      } else {
        this.companies.logo = file;
      }
    },
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + "companies",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.companies;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nueva Empresa")
        : (this.modal.title = "Editar Empresa");
      if (action == "store") {
        this.companies = {
          id: "",
          reason_social: "",
          ruc: "",
          phone: "",
          email: "",
          logo: "",
          company: this.$store.getters.get_company,
          state: 0,
          box: 0,
          devolution: 0,
        };
        this.employees = {
          id: "",
          name: "",
          last_name: "",
          dni: "",
          phone: "",
          address: "",
        };
        this.errors = {
          ruc: "",
          phone: "",
          dni: "",
          phone_employee: "",
          email_company: "",
          email_employee: "",
        };
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = "";
        }
      } else {
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = "";
        }
        this.companies = this.companies;
        this.employees = this.employees;
        this.errors = {
          ruc: "",
          phone: "",
          dni: "",
          phone_employee: "",
          email_company: "",
          email_employee: "",
        };
      }
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      if (!this.validateForm()) return;

      let formData = new FormData();
      formData.append("company", this.$store.getters.get_company);
      formData.append("id", this.companies.id);
      formData.append("reason_social", this.companies.reason_social);
      formData.append("ruc", this.companies.ruc);
      formData.append("phone", this.companies.phone);
      formData.append("email", this.companies.email);
      formData.append("box", this.companies.box);
      formData.append("devolution", this.companies.devolution);
      formData.append("logo", this.companies.logo);
      formData.append("state", this.companies.state);
      if (this.modal.action === "store") {
        formData.append("employee_name", this.employees.name);
        formData.append("employee_last_name", this.employees.last_name);
        formData.append("employee_dni", this.employees.dni);
        formData.append("employee_email", this.employees.email);
        formData.append("employee_address", this.employees.address);
        formData.append("employee_phone", this.employees.phone);
      }

      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: formData,
      })
        .then((response) => {
          if (
            response.data.state == 1 ||
            response.data.state == 2 ||
            response.data.state == 3 ||
            response.data.state == 4
          ) {
            if (response.data.state == 1) {
              Swal.fire({
                title: "RUC ya resgistrado",
                icon: "warning",
              });
            } else if (response.data.state == 2) {
              Swal.fire({
                title: "DNI ya resgistrado",
                icon: "warning",
              });
            } else if (response.data.state == 3) {
              Swal.fire({
                title: "Email de la Compañia ya resgistrado",
                icon: "warning",
              });
            } else {
              Swal.fire({
                title: "Email del Representante ya resgistrado",
                icon: "warning",
              });
            }
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedCompany = response.data.data.company;
              let updatedEmployee = response.data.data.employee;
              let index = this.data.findIndex(
                (company) => company.id === updatedCompany.id
              );
              if (index !== -1) {
                this.$set(this.data, index, {
                  ...updatedCompany,
                  ...updatedEmployee,
                });
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.errors.ruc = "";
          this.errors.phone = "";
          this.errors.dni = "";
          this.errors.phone_employee = "";

          this.companies = response;
          if (this.companies.logo) {
            let url_logo = this.companies.logo;
            let url = this.$store.getters.get__url;
            let urlModifie = url.replace("api/v1", "");
            this.companies.logo = url_logo.replace("http://localhost/", urlModifie);
          } else {
            this.companies.logo = "";
          }

          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => { });
    }
    ,
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          /*
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          */
          this.mtd_getdata();
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => { });
    },
    validateRUC() {
      if (this.companies.ruc.length != 11 || isNaN(this.companies.ruc)) {
        this.errors.ruc = "El RUC debe ser un número de máximo 11 dígitos";
      } else {
        this.errors.ruc = "";
      }
    },
    ValidateName() {
      const regex = /^[A-Za-z\s]+$/;
      if (!regex.test(this.employees.name)) {
        this.errors.name = "El nombre solo puede contener letras y espacios"
      } else {
        this.errors.name = "";
      }
    },
    ValidateLastName() {
      const regex = /^[A-Za-z\s]+$/;
      if (!regex.test(this.employees.last_name)) {
        this.errors.last_name = "El apellido solo puede contener letras y espacios"
      } else {
        this.errors.last_name = "";
      }
    },
    validatePhone() {
      if (this.companies.phone.length != 9 || isNaN(this.companies.phone)) {
        this.errors.phone =
          "El teléfono debe ser un número de máximo 9 dígitos";
      } else {
        this.errors.phone = "";
      }
    },
    validateDNI() {
      if (this.employees.dni.length != 8 || isNaN(this.employees.dni)) {
        this.errors.dni = "El DNI debe ser un número de máximo 8 dígitos";
      } else {
        this.errors.dni = "";
      }
    },
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email == this.companies.email) {
        if (!emailPattern.test(email)) {
          this.errors.email_company =
            "El correo electrónico no tiene un formato válido";
        } else {
          this.errors.email_company = "";
        }
      } else if (email == this.employees.email) {
        if (!emailPattern.test(email)) {
          this.errors.email_employee =
            "El correo electrónico no tiene un formato válido";
        } else {
          this.errors.email_employee = "";
        }
      }
    },

    validatePhoneEmployee() {
      if (this.employees.phone.length != 9 || isNaN(this.employees.phone)) {
        this.errors.phone_employee =
          "El teléfono debe ser un número de máximo 9 dígitos";
      } else {
        this.errors.phone_employee = "";
      }
    },
    validateForm() {
      // this.ValidateName();
      // this.ValidateLastName();
      this.validateRUC();
      this.validatePhone();
      this.validateEmail(this.companies.email);
      if (this.modal.action === "store") {
        this.ValidateName();
        this.ValidateLastName();
        this.validateDNI();
        this.validatePhoneEmployee();
        this.validateEmail(this.employees.email);
      }

      return (
        this.errors.ruc === "" &&
        this.errors.phone === "" &&
        this.errors.dni === "" &&
        this.errors.phone_employee === "" &&
        this.errors.email_company === "" &&
        this.errors.email_employee === ""
      );
    },

    changeSwitch: function (payload) {
      this.companies.state = payload == true ? 1 : 0;
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
  .btn-search {
    color: #fff;
    background-color: #2819ae;
    border-color: #2517a3;
  }

  .container-img {
    margin: 10px 0 0 0;
    display: flex;
    justify-content: center;
  }
</style>