<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de Horarios"
          icon="fas fa-coins"
          btn_name="Horario"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Sedes"
            placeholder="Seleccione sede"
            :options="data_campus"
            :value.sync="schedule.campu_id"
            :disabled="this.$store.getters.get__user_id !== 1"
          />
          <div v-if="modal.action =='store'" class="contenedor">
            <label for="">Días</label>
            <div class="checkbox">
              <ul>
                <li v-for="(day, index) in days" :key="index">
                  <input
                    type="checkbox"
                    :id="`check_${day.value}`"
                    :value="day.value"
                    v-model="selectedDays"
                  />
                  <label :for="`check_${day.value}`">{{ day.label }}</label>
                </li>
              </ul>
            </div>
          </div>
          <cSelectForm
            v-else
            label="Dia"
            placeholder="Seleccione dia"
            :options="days"
            :value.sync="schedule.day"
          />
        </CCol>
        <CCol sm="12">
          <label for="start_time">Hora de Inicio</label>
          <input
            type="time"
            v-model="schedule.start_time"
            class="form-control"
            id="start_time"
          />
        </CCol>

        <CCol sm="12">
          <label for="end_time">Hora de Fin</label>
          <input
            type="time"
            v-model="schedule.end_time"
            class="form-control"
            id="end_time"
          />
        </CCol>

        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="schedule.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Rol'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "campu_name", label: "Sede", _style: "width:20%;" },
  { key: "day", label: "Día", _style: "width:20%;" },
  { key: "start_time", label: "Entrada", _style: "width:20%;" },
  { key: "end_time", label: "Cierra", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";

export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    CSwitchVue,
    cSelectForm,
  },
  data() {
    return {
      prefix: "schedules",
      fields,
      data: [],
      data_campus: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      schedule: {
        id: "",
        campu_id: "",
        campu_name: "",
        day:"",
        start_time: "",
        end_time: "",
        state: "",
      },
      selectedDays: [], // Arreglo donde se guardarán los días seleccionados
      modal_delete: {
        boo: false,
        item: [],
      },
      days: [
        { value: "LUNES", label: "Lunes" },
        { value: "MARTES", label: "Martes" },
        { value: "MIERCOLES", label: "Miercoles" },
        { value: "JUEVES", label: "Jueves" },
        { value: "VIERNES", label: "Viernes" },
        { value: "SABADO", label: "Sábado" },
        { value: "DOMINGO", label: "Domingo" },
      ],
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.schedule.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (
        this.schedule.campu_id != "" &&
        this.schedule.day != "" &&
        this.schedule.end_time != "" &&
        this.schedule.end_time != ""
      )
        return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
    this.indexCampus();
  },
  watch: {
    selectedDays(newValue) {
      this.schedule.day = newValue; // actualizar la propiedad day del schedule
      this.processSelectedDays();
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    processSelectedDays() {

    },
    indexCampus: function () {
      this.get({
        url: this.$store.getters.get__url + "/schedules/1",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (this.$store.getters.get_all_user.id === 1) {
            response.data.forEach((element) => {
              this.data_campus.push({
                label: element.name,
                value: element.id,
              });
            });
          } else {
            response.data.forEach((element) => {
              if (element.id === this.$store.getters.get_campu.value) {
                this.data_campus.push({
                  label: element.name,
                  value: element.id,
                });
              }
            });
          }
        })
        .catch((errors) => {});
    },
    mtd_getdata: function () {
      let url;
      if(this.$store.getters.get__user_id==1){
        url = this.$store.getters.get__url + "/" + this.prefix+"/index/"+this.$store.getters.get__user_id+"/1";
      }else{
        url= this.$store.getters.get__url + "/" + this.prefix+"/index/"+this.$store.getters.get__user_id+"/"+this.$store.getters.get_campu.value;
      }
      this.get({
        url: url,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.schedules;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Horario")
        : (this.modal.title = "Editar Horario");
      action == "store"
        ? (this.schedule = {
            id: "",
            campu_id: this.$store.getters.get__user_id == 1 ? "" : this.$store.getters.get_campu.value,
            campu_name:"",
            day: "",
            start_time: "",
            end_time: "",
            // company: this.$store.getters.get_company,
            state: "",
          },
          this.selectedDays = [])
        : (this.schedule = { ...this.schedule });
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.schedule,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Horario ya resgistrado",
              icon: "warning",
            });
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.selectedDays = [];
              this.mtd_getdata();
            } else {
              let updatedSchedule = response.data.data;
              let index = this.data.findIndex(schedule => schedule.id === updatedSchedule.id);
              if (index !== -1) {
                this.$set(this.data, index, updatedSchedule);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };

            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.schedule = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {});
    },
    changeSwitch: function (payload) {
      this.schedule.state = payload == true ? 1 : 0;
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
.contenedor {
  width: 100%;
}
.checkbox {
  display: flex;
  width: 100%;
}
.checkbox ul {
  display: flex;
  flex-wrap: wrap;
  //  border: 2px solid red;
  list-style: none;
  margin: 0 0 0 0 !important;
  padding: 0 0;
}

.checkbox ul li {
  margin: 0 0;
  display: inline-block;
}

.checkbox ul li label {
  display: inline-block;
  background-color: #dee2e6;
  border: 1px solid white;
  border-radius: 8px;
  color: #2b3241;
  transition: 0.2s all ease;
  padding: 8px 6px;
}

.checkbox ul li label:hover {
  border: 1px solid #688ffd;
}

.checkbox ul li input[type="checkbox"]:checked + label {
  border: 1px solid #688ffd;
  background-color: #688ffd;
  color: white;
}

.checkbox ul li input[type="checkbox"] {
  opacity: 0;
}
</style>
