<template>
  <div>
    <HomeMaintenceVue v-if="page == 'home'" @changePage="changePage" :userRoles="userRoles" />
    <RolesVue class="pt-1" v-if="hasRoles([1, 2]) && page=='roles'" @mtdBack="mtdBack" />
    <UsersVue class="pt-1" v-if="hasRoles([1, 2]) && page=='users'" @mtdBack="mtdBack" />
    <CampusVue class="pt-1" v-if="hasRoles([1, 2]) && page=='campus'" @mtdBack="mtdBack" />
    <BoxVue class="pt-1" v-if="hasRoles([2]) && page=='boxes'" @mtdBack="mtdBack" />
    <CompanyVue class="pt-1" v-if="hasRoles([1]) && page=='company'" @mtdBack="mtdBack" />
    <ScheduleVue class="pt-1" v-if="hasRoles([1, 2]) && page=='schedule'" @mtdBack="mtdBack" />
    <SportVue class="pt-1" v-if="hasRoles([1, 2]) && page=='sport'" @mtdBack="mtdBack" />
    <Type_of_FieldVue class="pt-1" v-if="hasRoles([1, 2]) && page=='type_of_field'" @mtdBack="mtdBack" />
    <RateVue class="pt-1" v-if="hasRoles([1, 2]) && page=='rate'" @mtdBack="mtdBack" />
    <EmployeeVue class="pt-1" v-if="hasRoles([1, 2]) && page=='employee'" @mtdBack="mtdBack" />
    <EmployeexCampuVue class="pt-1" v-if="hasRoles([1,2]) && page=='employeexcampu'" @mtdBack="mtdBack" />
    <Sport_FieldVue class="pt-1" v-if="hasRoles([1, 2]) && page=='sport_field'" @mtdBack="mtdBack" />
    <ImageVue class="pt-1" v-if="hasRoles([1, 2]) && page=='image'" @mtdBack="mtdBack" />
    <Sport_FieldxSportVue class="pt-1" v-if="hasRoles([1, 2]) && page=='Sport_FieldxSport'" @mtdBack="mtdBack" />
    <Document_TypeVue class="pt-1" v-if="hasRoles([1, 2]) && page=='document_type'" @mtdBack="mtdBack" />
    <Method_PaymentVue class="pt-1" v-if="hasRoles([2]) && page=='method_payment'" @mtdBack="mtdBack" />
    <ProductVue class="pt-1" v-if="hasRoles([2]) && page=='product'" @mtdBack="mtdBack" />
    <UnitVue class="pt-1" v-if="hasRoles([2]) && page=='unit'" @mtdBack="mtdBack" />
    <ProviderVue class="pt-1" v-if="hasRoles([2]) && page=='provider'" @mtdBack="mtdBack"/>
    <AlmacenVue class="pt-1" v-if="hasRoles([2]) && page=='almacen'" @mtdBack="mtdBack"/>
    <!-- <BookingVue class="pt-1" v-if="page=='booking'" @mtdBack="mtdBack" /> -->
    <!-- <Sport_FieldxSportVue class="pt-1" v-if="page=='sport_fieldxsport'" @mtdBack="mtdBack" /> -->
  </div>
</template>

<script>
import BoxVue from '../../components/maintenance/Box.vue';
import CampusVue from '../../components/maintenance/Campus.vue';
import CompanyVue from '../../components/maintenance/Company.vue';
import HomeMaintenceVue from '../../components/maintenance/home.vue';
import ScheduleVue from '../../components/maintenance/Schedulee.vue';
import SportVue from '../../components/maintenance/Sport.vue';
import Type_of_FieldVue from '../../components/maintenance/Type_of_Field.vue';
import RateVue from '../../components/maintenance/RatesNew.vue';
import EmployeeVue from '../../components/maintenance/Employee.vue';
import EmployeexCampuVue from '../../components/maintenance/EmployeexCampu.vue';
import Sport_FieldVue from '../../components/maintenance/Sport_Field.vue';
import ImageVue from '../../components/maintenance/Image.vue';
import Sport_FieldxSportVue from '../../components/maintenance/Sport_FieldxSport.vue';
import Document_TypeVue from '../../components/maintenance/DocumentType.vue';
import Method_PaymentVue from '../../components/maintenance/MethodPayment.vue';
import ProductVue from '../../components/maintenance/Product.vue';
import UnitVue from '../../components/maintenance/Unit.vue';
import ProviderVue from '../../components/maintenance/Provider.vue';
import AlmacenVue from '../../components/maintenance/Almacen.vue';
// import BookingVue from '../../components/maintenance/Booking.vue';

import RolesVue from '../access/roles.vue';
import UsersVue from '../access/users.vue';

export default {
  components: {
    HomeMaintenceVue,
    RolesVue,
    UsersVue,
    CampusVue,
    BoxVue,
    CompanyVue,
    ScheduleVue,
    SportVue,
    Type_of_FieldVue,
    RateVue,
    EmployeeVue,
    EmployeexCampuVue,
    Sport_FieldVue,
    ImageVue,
    Sport_FieldxSportVue,
    Document_TypeVue,
    Method_PaymentVue,
    ProductVue,
    UnitVue,
    ProviderVue,
    AlmacenVue,
  },
  data() {
    return {
      page: 'home',
      userRoles: this.$store.getters.get_roles.map(role => role.role.id)
    }
  },
  methods: {
    changePage(page) {
      this.page = page;
      this.$emit("mtdViewFooter", false, 'mantenimiento');
    },
    mtdBack() {
      this.page = 'home';
      this.$emit("mtdViewFooter", true, 'mantenimiento');
    },
    hasRoles(roles) {
      return roles.some(role => this.userRoles.includes(role));
    }
  }
};
</script>
