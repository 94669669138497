<template>
  <div>
    <br />
    <CRow>
      <CCol md="12" lg="12" sm="12" xs="12"  class="d-flex justify-content-between">
        <button
          v-if="verificBead == false && this.box"
          @click="mtdOpenModalOpenBead"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-cash-register"></i> Abrir Caja
        </button>

        <!-- Botón para cerrar caja -->
        <button
          v-if="verificBead == true && this.box"
          @click="mtdCloseBead"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-cash-register"></i> Cerrar Caja
        </button>
        <button
          @click="mtdBackCampus"
          type="button"
          class="btn btn-sm text-white"
          style="background: #926cdc"
        >
          <i class="fas fa-hand-point-left"></i> Cambiar de sede
        </button>
      </CCol>
    </CRow>
    <br />
    <div class="contenedor">
      <div class="contenedor-img">
        <img
          :src="company.logo ? 'https://iareservagol.howertpi.com/assets/' + company.logo : 'https://iareservagol.howertpi.com/assets/img/Images/a611f950-ef4c-45d5-8d96-8986d4d1ac5f.png'"
          class="img-thumbnail imagen"
          alt="campo deportivo"
        />
      </div>
    </div>
    <!-- <div class="container mx-auto p-4"> -->
      <cModalOpenBead
        :title="modalOpenBead.title"
        :boo_modal="modalOpenBead.modal_form"
        @closeModalOpenBead="closeModalOpenBead"
        @save="handleSave"
      >
      </cModalOpenBead>
    <!-- </div> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cModalOpenBead from "../receptionist/modals/cModalOpenBead.vue";

export default {
  name: "home-adminCompany",
  components: {
    cModalOpenBead,
  },
  data() {
    return {
      prefix: "beads",
      box: false,
      verificBead: false,
      modalOpenBead: {
        title: "Registrar monto de inicio",
        modal_form: false,
      },
      company: {
        logo: "",
      },
    };
  },
  created() {
    this.showBead();
    this.showCompany();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdCloseBead: function () {
      this.$emit("setView", "close_bead");
    },
    mtdOpenModalOpenBead() {
      this.modalOpenBead = {
        title: "Registrar monto de inicio",
        modal_form: true,
      };
    },
    closeModalOpenBead() {
      this.modalOpenBead = {
        modal_form: false,
      };
    },
    showBead() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/showBead/" +
          this.$store.getters.get_campu.value,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.verificBead = response.data ? true : false;
            this.bead = this.verificBead == true ? response.data : {};
          }
        })
        .catch((errors) => {});
    },
    showCompany() {
      this.get({
        url:
          this.$store.getters.get__url +
          "/beads/showCompany/" +
          this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            this.company.logo = response.data.logo;
            this.box = response.data.box;
          }
        })
        .catch((errors) => {});
    },
    handleSave() {
      this.showBead();
      this.modalOpenBead = {
        modal_form: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 460px;
  padding: 10px;

  // Pantallas medianas
  @media (max-width: 768px) {
    height: 400px;
  }

  // pantallas pequeñas
  @media (max-width: 480px) {
    height: auto;
    padding: 5px;
    margin: 10px 0;
  }
}
.contenedor-img {
  width: 35%;
  height: 80%;
   // Pantallas medianas
   @media (max-width: 768px) {
    width: 50%;
  }

  // pantallas pequeñas
  @media (max-width: 480px) {
    width: 60%;
  }
}
.imagen {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 0px;
}
</style>
