<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper :items="data" :fields="fields" hover :striped="true" :border="true" small fixed
          caption="Lista de Unidades" icon="fas fa-coins" btn_name="Unidad" @mtdBack="mtdBack"
          @show_modal="mtd_open_modal" @action_edit="mtd_show" @action_delete="mtd_delete" :size="'md'"
          :button_new="true" :actions="true" :withActions="'2%'" :buttonEdit="true" :buttonDelete="true" />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="modal.title" :button="cp_button"
      :show.sync="modal.modal_form" @mtd_action="mtd_action" :color="'info'" :centered="true">
      <CRow>
        <CCol sm="12">
          <CInput label="Nombre" placeholder="Digite nombre" v-model="unit.name" />
        </CCol>
        <CCol sm="12">
          <cSelectForm label="Unidad Base" placeholder="Seleccione unidad" :options="data_unit"
            :value.sync="unit.unit_id" @change="checkUnitBase" />
        </CCol>

        <CCol sm="12">
          <CInput label="Cantidad" placeholder="Digite cantidad" type="number" v-model="unit.quantity_conversion"
            :disabled="isQuantityDisabled" />
        </CCol>
        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue :key="componentKey" @changeSwitch="changeSwitch" class="mx-1 pl-2 pt-2"
            :checked="unit.state == 1 ? true : false" color="success" variant="opposite" shape="pill"
            v-bind="labelIcon" />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete :backdrop="true" :closeOnBackdrop="true" :size="'md'" :title="'Borrar Unidad'"
      :show.sync="modal_delete.boo" @mtd_commit="mtd_commit" :centered="true" :item="modal_delete.item" />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "unitfk_name", label: "Unidad Base", _style: "width:20%;" },
  { key: "quantity_conversion", label: "Cantidad", _style: "width:20%;" },
  { key: "quantity", label: "Cantidad en Unidad", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import cSelectForm from "../shared/inputs/cSelect.vue";
import CSwitchVue from "../shared/switch/CSwitch.vue";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, CSwitchVue, cSelectForm },
  data() {
    return {
      prefix: "units",
      fields,
      data: [],
      data_unit: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      unit: {
        id: "",
        company_id: this.$store.getters.get_company.id,
        name: "",
        quantity: "",
        unit_id: "",
        quantity_conversion: "",
        state: 0,
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      isQuantityDisabled: false,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.unit.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (this.unit.name != "" &&
        this.unit.quantity_conversion != "" &&
        this.unit.unit_id) return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
    // this.indexUnit();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix + "/index/" + this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.data;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          })
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    indexUnit: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix + "/byUnit/" + this.$store.getters.get_company.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.data_unit.push({
              label: element.name,
              value: element.id,
              detail: element,
            })
          })
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    checkUnitBase() {
      this.isQuantityDisabled = this.unit.unit_id === 1;
    },
    mtd_open_modal: function (boo, action) {
      this.data_unit = [],
        this.indexUnit();
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nueva Unidad")
        : (this.modal.title = "Editar Unidad");
      action == "store"
        ? (this.unit = {
          id: "",
          company_id: this.$store.getters.get_company.id,
          name: "",
          quantity_conversion: 1,
          unit_id: "",
          state: "",
        })
        : (this.unit = { ...this.unit });
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.unit,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Unidad ya resgistrado",
              icon: "warning"
            });
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
              // this.indexUnit();
            } else {
              let updatedUnit = response.data.data;
              let index = this.data.findIndex(unit => unit.id === updatedUnit.id);
              if (index !== -1) {
                this.$set(this.data, index, updatedUnit);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
              // this.indexUnit();
            }
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            this.unit = { id: "", company_id: this.$store.getters.get_company.id, name: "", quantity_conversion: 1, state: 0 };

            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.unit = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => { });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };
          this.mtd_getdata();
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => { });
    },
    changeSwitch: function (payload) {
      this.unit.state = payload == true ? 1 : 0;
    },
  },
  destroyed() {
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
  .btn-search {
    color: #fff;
    background-color: #2819ae;
    border-color: #2517a3;
  }
</style>
