<template>
  <div>
    <CRow>
      <CCol md="12" lg="12" xl="12" xxl="12" xs="12" sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de tarifa"
          icon="fas fa-coins"
          btn_name="Tarifas"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="true"
          :actions="true"
          :withActions="'2%'"
          :buttonEdit="true"
          :buttonDelete="true"
        />
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <cSelectForm
            label="Sede"
            placeholder="Seleccione Sede"
            :options="data_campus"
            :value.sync="rate.campu_id"
            @change="indexSport_Field"
            :disabled="this.$store.getters.get__user_id !== 1"
          />

          <cSelectForm
            label="Campo Deportivo"
            placeholder="Seleccione Campo Deportivo"
            :options="data_sport_field"
            :value.sync="rate.sport_field_id"
            @change="indexSport"
            :disabled="modal.action !== 'store'"
          />

          <cSelectForm
            label="Deporte"
            placeholder="Seleccione deporte"
            :options="data_sport"
            :value.sync="rate.sport_id"
            :disabled="modal.action !== 'store'"
          />
          <!-- Turnos -->
          <div v-if="modal.action == 'store'" class="contenedor">
            <label for="">Turnos</label>
            <div class="checkbox2">
              <ul>
                <li v-for="(tur, index) in shifts" :key="index">
                  <input
                    type="checkbox"
                    :id="`check_${tur.value}`"
                    :value="tur.value"
                    v-model="selectedTurn"
                    @change="handleSingleSelection(tur.value)"
                  />
                  <label :for="`check_${tur.value}`">{{ tur.label }}</label>
                </li>
              </ul>
            </div>
          </div>
          <cSelectForm
            v-else
            label="Turno"
            placeholder="Seleccione Turno"
            :options="shifts"
            :value.sync="rate.shift"
            disabled
          />

          <!-- DIAS -->
          <div
            v-if="modal.action == 'store' && selectedTurn.length > 0"
            class="contenedor"
          >
            <label for="">Días</label>
            <div class="checkbox">
              <ul>
                <li
                  v-for="(item, index) in mostrardays"
                  :key="index"
                  v-if="selectedTurn.includes(item.shift)"
                >
                  <ul>
                    <li v-for="(day, dayIndex) in item.days" :key="dayIndex">
                      <input
                        type="checkbox"
                        :id="`check_${day}`"
                        :value="day"
                        v-model="selectedDays"
                      />
                      <label :for="`check_${day}`">{{ day }}</label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <cSelectForm
            v-if="modal.action == 'update'"
            label="Dia"
            placeholder="Seleccione dia"
            :options="days"
            :value.sync="rate.day"
            disabled
          />

          <CInput
            label="Precio x Hora"
            placeholder="Digite precio"
            v-model="rate.price"
            type="number"
            step="1.00"
          />
        </CCol>
        <CCol v-if="modal.action != 'store'" sm="12">
          <label for="">Estado</label>
          <CSwitchVue
            :key="componentKey"
            @changeSwitch="changeSwitch"
            class="mx-1 pl-2 pt-2"
            :checked="rate.state == 1 ? true : false"
            color="success"
            variant="opposite"
            shape="pill"
            v-bind="labelIcon"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar Rol'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>

<script>
const fields = [
  { key: "index", label: "Id", _style: "width:1%" },
  { key: "sport_field_name", label: "Campo Deportivo", _style: "width:1%" },
  { key: "sport_name", label: "Deporte", _style: "width:1%" },
  { key: "day", label: "Día", _style: "width:20%;" },
  { key: "shift", label: "Turno", _style: "width:20%;" },
  { key: "price", label: "Precio", _style: "width:20%;" },
  { key: "state", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../shared/datatable/Table.vue";
import CModalForm from "../shared/modals/cModalForm.vue";
import cModalDelete from "../shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
import { CSwitch } from "@coreui/vue-pro";
import CSwitchVue from "../shared/switch/CSwitch.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";

export default {
  components: {
    CTableWrapper,
    CModalForm,
    cModalDelete,
    CSwitchVue,
    cSelectForm,
  },
  data() {
    return {
      prefix: "rates",
      fields,
      data: [],
      mostrardays: [],
      selectedDays: [],
      selectedTurn: [],
      data_campus: [],
      data_sport_field: [],
      data_sport: [],
      data_schedule: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      rate: {
        id: "",
        campu_id: "",
        sport_field_id: "",
        sport_id: "",
        shift: "",
        price: "",
        state: 0,
      },
      modal_delete: {
        boo: false,
        item: [],
      },
      days: [
        { value: "LUNES", label: "LUNES" },
        { value: "MARTES", label: "MARTES" },
        { value: "MIERCOLES", label: "MIERCOLES" },
        { value: "JUEVES", label: "JUEVES" },
        { value: "VIERNES", label: "VIERNES" },
        { value: "SABADO", label: "SABADO" },
        { value: "DOMINGO", label: "DOMINGO" },
      ],
      shifts: [
        { value: "Dia", label: "Día" },
        { value: "Tarde", label: "Tarde" },
        { value: "Noche", label: "Noche" },
      ],
      shifts_horas: {
        early_morning: { start: "00:00", end: "05:59" },
        morning: { start: "06:00", end: "11:59" },
        afternoon: { start: "12:00", end: "17:59" },
        night: { start: "18:00", end: "23:59" },
      },
      labelIcon: {
        labelOn: "\u2713",
        labelOff: "\u2715",
      },
      componentKey: 0,
      // dataStore: [],
    };
  },
  computed: {
    cpStatus: function () {
      if (this.rate.state == 1) return true;
      return false;
    },
    cp_button: function () {
      if (this.modal.action == "store") {
        if (
          this.selectedDays.length > 0 &&
          this.rate.price &&
          this.rate.sport_field_id &&
          this.rate.sport_id
        )
          return false;
        return true;
      } else {
        if (this.rate.price) return false;
        return true;
      }
    },
  },
  created() {
    this.mtd_getdata();
    this.indexCampus();
  },
  watch: {
    "rate.campu_id": function (newId) {
      if (newId) {
        this.indexSport_Field();
      }
    },
    "rate.sport_field_id": function (newId) {
      if (newId) {
        this.indexSport();
      }
    },
    selectedTurn: function (newValue) {
      if (newValue) {
        this.shiftxday();
      }
      if (newValue.length == 1) {
        this.rate.shift = "";
        this.rate.shift = newValue[0]; // Guardar el turno en rate.shift
        // Limpiar los turnos anteriores
        this.mostrardays = [];
        this.data_schedule.forEach((time) => {
          const startTime = time.start_time;
          const endTime = time.end_time;

          // Convertir a un formato más simple (por ejemplo, "09:00:00" -> "09:00")
          const compareStart = startTime.slice(0, 5);
          const compareEnd = endTime.slice(0, 5);

          // Función para agregar un turno
          const addShift = (shiftName) => {
            // Buscar si ya existe un registro para el turno
            let existingShift = this.mostrardays.find((item) => {
              return item.shift === shiftName;
            });

            if (existingShift) {
              // Si el turno ya existe, agregar el día si no está incluido
              if (!existingShift.days.includes(time.day)) {
                existingShift.days.push(time.day);
              }
            } else {
              // Si el turno no existe, crear un nuevo registro con el turno y el día
              this.mostrardays.push({
                shift: shiftName,
                days: [time.day],
              });
            }
          };

          // Verificar cada turno
          if (compareStart == compareEnd) {
            addShift("Dia");
            addShift("Tarde");
            addShift("Noche");
          } else {
            if (
              compareStart <= this.shifts_horas.early_morning.end &&
              compareEnd >= this.shifts_horas.early_morning.start 
            ) {
              addShift("Noche");
            }
            
            if (
              compareStart <= this.shifts_horas.morning.end &&
              compareEnd >= this.shifts_horas.morning.start
            ) {
              addShift("Dia");
            }

            if (
              compareStart <= this.shifts_horas.afternoon.end &&
              compareEnd >= this.shifts_horas.afternoon.start
            ) {
              addShift("Tarde");
            }

            if (
              compareStart <= this.shifts_horas.night.end &&
              compareEnd >= this.shifts_horas.night.start
            ) {
              addShift("Noche");
            }
          }
        });
      }
    },
    selectedDays: function (newDays) {
      // Guardar los días seleccionados en rate.day como una cadena separada por comas
      this.rate.day = newDays;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    handleSingleSelection(selectedValue) {
      // Asegurarse de que solo se seleccione un turno a la vez
      if (this.selectedTurn.length > 1) {
        this.selectedTurn = [selectedValue]; // Mantener solo el turno seleccionado
        this.selectedDays = [];
      }
    },
    shiftxday: function () {
      this.mostrardays = []; // Reset the mostrardays array
      this.selectedTurn.forEach((turn) => {
        this.data_schedule.forEach((time) => {
          const startTime = time.start_time.slice(0, 5);
          const endTime = time.end_time.slice(0, 5);

          // Función para agregar un turno
          const addShift = (shiftName) => {
            let existingShift = this.mostrardays.find((item) => {
              return item.shift === shiftName;
            });

            if (existingShift) {
              if (!existingShift.days.includes(time.day)) {
                existingShift.days.push(time.day);
              }
            } else {
              this.mostrardays.push({
                shift: shiftName,
                days: [time.day],
              });
            }
          };

          // Verificar si el horario es de 24 horas
          if (startTime === endTime) {
            // Agregar el turno a Día, Tarde y Noche
            addShift("Dia");
            addShift("Tarde");
            addShift("Noche");
          } else {
            // Verificar los otros turnos
            if (
              startTime <= this.shifts_horas.early_morning.end &&
              endTime >= this.shifts_horas.early_morning.start &&
              turn === "Noche"
            ) {
              addShift("Noche");
            }

            if (
              startTime <= this.shifts_horas.morning.end &&
              endTime >= this.shifts_horas.morning.start &&
              turn === "Dia"
            ) {
              addShift("Dia");
            }

            if (
              startTime <= this.shifts_horas.afternoon.end &&
              endTime >= this.shifts_horas.afternoon.start &&
              turn === "Tarde"
            ) {
              addShift("Tarde");
            }

            if (
              startTime <= this.shifts_horas.night.end &&
              endTime >= this.shifts_horas.night.start &&
              turn === "Noche"
            ) {
              addShift("Noche");
            }
          }
        });
      });
    },

    indexCampus: function () {
      this.get({
        url: this.$store.getters.get__url + "/rates/campu",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (this.$store.getters.get__user_id == 1) {
            response.data.data.forEach((element) => {
              this.data_campus.push({
                label: element.name,
                value: element.id,
                schedule: element.schedule,
              });
            });
          } else {
            // Carga los campus para usuarios que son administradores
            let selectedCampus = response.data.data.find(
              (element) => element.id == this.$store.getters.get_campu.value
            );
            if (selectedCampus) {
              this.data_campus.push({
                label: selectedCampus.name,
                value: selectedCampus.id,
              });
              this.data_schedule = selectedCampus.schedule;
              // Ordenar data_schedule por días de la semana
              const dayOrder = [
                "LUNES",
                "MARTES",
                "MIERCOLES",
                "JUEVES",
                "VIERNES",
                "SABADO",
                "DOMINGO",
              ];
              this.data_schedule.sort((a, b) => {
                return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
              });
            }
          }
        })
        .catch((errors) => {});
    },
    indexSport_Field: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/rates/sportfield/" +
          this.rate.campu_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // Carga los campus para admin Howert
          if (this.$store.getters.get__user_id == 1) {
            let selectedCampus = this.data_campus.find(
              (element) => element.value == this.rate.campu_id
            );
            if (selectedCampus) {
              this.data_schedule = selectedCampus.schedule;
              // Ordenar data_schedule por días de la semana
              const dayOrder = [
                "LUNES",
                "MARTES",
                "MIERCOLES",
                "JUEVES",
                "VIERNES",
                "SABADO",
                "DOMINGO",
              ];
              this.data_schedule.sort((a, b) => {
                return dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day);
              });
            }
          }
          this.data_sport_field = [];
          this.data_sport = [];
          response.data.data.forEach((element) => {
            this.data_sport_field.push({
              label: element.name,
              value: element.id,
            });
          });
        })
        .catch((errors) => {});
    },
    indexSport: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/rates/sport/" +
          this.rate.sport_field_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_sport = [];
          response.data.data.forEach((element) => {
            this.data_sport.push({
              label: element.name,
              value: element.id,
            });
          });
        })
        .catch((errors) => {});
    },
    mtd_getdata: function () {
      let url;
      if (this.$store.getters.get__user_id == 1) {
        url =
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get__user_id +
          "/1";
      } else {
        url =
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/index/" +
          this.$store.getters.get__user_id +
          "/" +
          this.$store.getters.get_campu.value;
      }
      this.get({
        url: url,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.rates;
          let index = 1;
          this.data.forEach((arr) => {
            arr.index = index;
            index++;
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      if (action == "store") {
        this.modal.title = "Nueva Tarifa";
      } else {
        this.modal.title = "Editar Tarifa";
      }
      if (action == "store") {
        this.rate = {
          id: "",
          campu_id:
            this.$store.getters.get__user_id == 1
              ? ""
              : this.$store.getters.get_campu.value,
          sport_field_id: "",
          sport_id: "",
          day: "",
          shift: "",
          price: "",
          state: "",
        };
        this.selectedTurn = [];
        this.selectedDays = [];
      } else {
        this.rate = { ...this.rate };
      }
      if (this.modal.action != "store") {
        this.componentKey += 1;
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.rate,
      })
        .then((response) => {
          if (response.data.state == 1) {
            Swal.fire({
              title: "Tarifa(s) ya resgistrada(s)",
              icon: "warning",
            });
          } else if (response.data.state == 2) {
            Swal.fire({
              title: "REGISTRO CORRECTO",
              text: response.data.existing,
              icon: "success",
            });
            this.mtd_getdata();
          } else {
            let color = "success";
            let message = "";
            if (this.modal.action == "store") {
              message = "REGISTRADO CORRECTAMENTE";
              this.mtd_getdata();
            } else {
              let updatedRate = response.data.data;
              let index = this.data.findIndex(
                (rate) => rate.id === updatedRate.id
              );
              if (index !== -1) {
                this.$set(this.data, index, updatedRate);
              }
              message = "EDITADO CORRECTAMENTE";
              this.mtd_getdata();
            }

            bus.$emit("alert", {
              color: color,
              message: message,
            });
          }
          if (response.data.state !== 1) {
            this.modal = {
              action: "",
              title: "",
              modal_form: false,
              name: "",
            };
            this.rate = {
              id: "",
              campu_id: "",
              sport_field_id: "",
              sport_id: "",
              day: "",
              shift: "",
              price: "",
              state: 0,
            };
            this.selectedTurn = [];
            this.selectedDays = [];
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.rate = response;
          this.rate.campu_id = response.sport_fieldxsport.sport_field.campu_id;
          this.rate.sport_field_id = response.sport_fieldxsport.sport_field_id;
          this.rate.sport_id = response.sport_fieldxsport.sport_id;
          this.rate.day = response.day.toUpperCase();
          // Cargar los deportes basados en el campo deportivo seleccionado
          this.indexSport();
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.id != response.data.id) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete = {
            boo: false,
            item: [],
          };

          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "success",
            message: "ELIMINADO CORRECTAMENTE",
          });
        });
    },
    changeSwitch: function (payload) {
      this.rate.state = payload == true ? 1 : 0;
    },
  },
  destroyed() {
    // console.log(this.dataStore);
    // this.$store.commit("mt_set_datacampus", this.dataStore);
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
.contenedor {
  width: 100%;
}
.checkbox {
  display: flex;
  width: 100%;
}
.checkbox ul {
  display: flex;
  flex-wrap: wrap;
  //  border: 2px solid red;
  list-style: none;
  margin: 0 0 0 0 !important;
  padding: 0 0;
}

.checkbox ul li {
  margin: 0 0;
  display: inline-block;
}

.checkbox ul li label {
  display: inline-block;
  background-color: #dee2e6;
  border: 1px solid white;
  border-radius: 8px;
  color: #2b3241;
  transition: 0.2s all ease;
  padding: 8px 6px;
}

.checkbox ul li label:hover {
  border: 1px solid #688ffd;
}

.checkbox ul li input[type="checkbox"]:checked + label {
  border: 1px solid #688ffd;
  background-color: #688ffd;
  color: white;
}

.checkbox ul li input[type="checkbox"] {
  opacity: 0;
}

//Turno
.checkbox2 {
  display: flex;
  width: 100%;
}
.checkbox2 ul {
  display: flex;
  flex-wrap: wrap;
  //  border: 2px solid red;
  list-style: none;
  margin: 0 0 0 0 !important;
  padding: 0 0;
}

.checkbox2 ul li {
  margin: 0 0;
  display: inline-block;
}

.checkbox2 ul li label {
  display: inline-block;
  background-color: #dee2e6;
  border: 1px solid white;
  border-radius: 8px;
  color: #2b3241;
  transition: 0.2s all ease;
  padding: 10px 50px;
}

.checkbox2 ul li label:hover {
  border: 1px solid #688ffd;
}

.checkbox2 ul li input[type="checkbox"]:checked + label {
  border: 1px solid #688ffd;
  background-color: #688ffd;
  color: white;
}

.checkbox2 ul li input[type="checkbox"] {
  opacity: 0;
}
</style>
